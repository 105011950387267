//====================================================
//  Function: Slider
//====================================================
function componentSlider(){
	heroSlider();
	// detailSlider();
}

function heroSlider(){
	if ($( '.js-slider-hero' ).length) {
		$( '.js-slider-hero' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: true,
			fade: true,
			cssEase: 'linear',
			autoplaySpeed: 3000,
			// cssEase: 'linear',
			speed: 500,
			variableWidth: false,
			prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon"><svg class="ico-svg icon-chevron-up"><use xlink:href="#icon-chevron-up"></use></svg> </span></span>',
			nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon"><svg class="ico-svg icon-chevron-down"><use xlink:href="#icon-chevron-down"></use></svg> </span></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon"><svg class="ico-svg icon-chevron-up"><use xlink:href="#icon-chevron-up"></use></svg> </span></span>',
						nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon"><svg class="ico-svg icon-chevron-down"><use xlink:href="#icon-chevron-down"></use></svg> </span></span>',
					}
				},
				{
					breakpoint: 200,
					settings: {
						prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon"><svg class="ico-svg icon-chevron-left"><use xlink:href="#icon-chevron-left"></use></svg> </span></span>',
						nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon"><svg class="ico-svg icon-chevron-right"><use xlink:href="#icon-chevron-right"></use></svg> </span></span>',
					}
				}
			]
		});
	}
}

// function detailSlider(){
// 	if ($( '.js-slider-main-product' ).length) {
// 		$( '.js-slider-main-product' ).slick({
// 			infinite: true,
// 			dots: false,
// 			slidesToShow:1,
// 			slidesToScroll: 1,
// 			autoplay: false,
// 			arrows: true,
// 			autoplaySpeed: 2000,
// 			// cssEase: 'linear',
// 			speed: 500,
// 			fade: true,
// 			prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon"><svg class="ico-svg icon-arrow-left"><use xlink:href="images/sprites.svg#icon-arrow-left"></use></svg> </span></span>',
// 			nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon"><svg class="ico-svg icon-arrow-right"><use xlink:href="images/sprites.svg#icon-arrow-right"></use></svg> </span></span>',
// 			variableWidth: false,
// 			mobileFirst: true,
// 			asNavFor:  '.js-slider-thumbnail-product' 
// 		});
// 	}

// 	if ($( '.js-slider-thumbnail-product' ).length) {
// 		$( '.js-slider-thumbnail-product' ).slick({
// 			infinite: true,
// 			dots: false,
// 			slidesToShow: 3,
// 			slidesToScroll: 1,
// 			arrows: false,
// 			autoplay: false,
// 			centerMode: true,
// 			autoplaySpeed: 2000,
// 			// cssEase: 'linear',
// 			speed: 500,
// 			asNavFor: '.js-slider-main-product' ,
// 			focusOnSelect: true,
// 			vertical: true,
// 			prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon"><svg class="ico-svg icon-arrow-left"><use xlink:href="images/sprites.svg#icon-arrow-left"></use></svg> </span></span>',
// 			nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon"><svg class="ico-svg icon-arrow-right"><use xlink:href="images/sprites.svg#icon-arrow-right"></use></svg> </span></span>',
// 			verticalSwiping: true
// 		});
// 	}
// }

