// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $('.header');
var bpSmall = 771;
var bpMedium = 992;
var bpLarge = 1200;
var classActive = 'is-active';
var classDisabled = 'is-disabled';
var classLast = 'is-last';
var classHasError = 'has-error';
var classLoading = 'is-loading';
var classOpened = 'is-opened';
var classSelected = 'is-selected';
var classCollapse = 'is-collapse';
var classAnimated = 'is-animated';
var $this = null;
var $containers = $('html,body');

// --- COMPONENTS, PARTS --- //
$(document).ready(function() {
	// ---  COMPONENTS --- //
	// Slider
	componentSlider();
	
	// Tabs
	componentTabs();
	
	// Google Maps
	componentGoogleMaps();
	
	// Gallery
	componentGallery();
	
	// Navigation
	componentFullscreenNavigation();
	

	// // ---  PART --- //
	// Detect IE
	partDetectIE();

	// Show Hidden
	partShowHidden();

	// Dropdown
	partDropdown();

});


// --- LOAD EVENT --- //
$(window).bind('load', function() {
	// Page Loader
	$('body').addClass(classActive);
});
