/* ==========================================================================
 *  Function: Dropdown
 * ======================================================================= */
function partDropdown(){
	if ($( '.js-dropdown-trigger' ).length){
		$( '.js-dropdown-trigger' ).on( 'click' , function () {
			if ($(window).width() < bpMedium){
				$(this).closest( '.js-dropdown-block' ).toggleClass(classActive);
				$(this).closest(".navigation").toggleClass("is-open-dropdown");
			}
		});

		$( '.js-dropdown-block.dropdown-block-header' ).hover(
			function() {
				if ($(window).width() > bpMedium){
					$( '.modal-overlay' ).addClass( classActive );
				}
			}, function() {
				if ($(window).width() > bpMedium){
					$( '.modal-overlay' ).removeClass( classActive );
				}
			}
		);
	}
}